<template>
  <v-container
    fluid
  >
    <v-tabs
      v-model="tab"
      centered
      class="adjust_slide_group"
      @change="change()"
    >
      <v-tab href="#package">
        {{ $i18n.t('$l.services.packageServices') }}
      </v-tab>
      <v-tab href="#investigation">
        {{ $i18n.t('$l.services.overLimitServices') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="package">
        <PackageProductsCardWrapper />
      </v-tab-item>
      <v-tab-item value="investigation">
        <InvestigationPlusCardWrapper
          ref="InvPlus"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import PackageProductsCardWrapper from './PackageProductsCardWrapper'
import InvestigationPlusCardWrapper from './InvestigationPlusCardWrapper'

export default {
  name: 'ServicesWrapper',
  components: {
    PackageProductsCardWrapper,
    InvestigationPlusCardWrapper
  },
  props: {
    category: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      tab: 'package'
    }
  },
  created () {
    if (this.category.length > 0) {
      this.tab = this.category
    }
  },
  methods: {
    change () {
      if (this.tab === 'investigation') {
        if (this.$refs.InvPlus) {
          this.$refs.InvPlus.loadData()
        }
      }
    }
  }
}
</script>

<style>
  .adjust_slide_group .v-slide-group__prev {
    min-width: 0px;
    padding: 0px;
    flex: unset;
  }
</style>
