<template>
  <div class="font-weight-light">
    <div class="font-weight-medium text-h5">
      {{ $i18n.t(`$l.product.${pseudoproduct.name}`) }}
    </div>
    <div>
      <span
        style="font-size:40px;"
        class="info--text pa-0 ma-0"
      >
        {{ formatStripePrice(isSuspendedActivation, pseudoproduct.stripePrice) }}</span>
    </div>
    <p class="ma-0 pa-0">
      {{ $i18n.t('$l.product.priceDepend') }}
    </p>

    <v-btn
      class="ma-0 pa-0 my-5"
      color="info1"
      block
      outlined
      rounded
      :disabled="isSuspendedActivation || !pseudoproduct.stripePrice"
      @click="checkOut(pseudoproduct)"
    >
      {{ $i18n.t('$l.product.buy') }}
    </v-btn>
    <div
      v-if="isSuspendedActivation"
      class="ma-0 mt-5 warning--text font-weight-medium"
    >
      {{ $i18n.t('$l.product.isSuspendedActivation') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'InvestigationPlusCard',
  props: {
    pseudoproduct: {
      type: Object,
      required: true
    }
  },
  computed: {
    isSuspendedActivation () {
      return !!this.pseudoproduct.metadata.isSuspendedActivation
    }
  },
  methods: {
    checkOut (pseudoproduct) {
      this.$router.push('/productCheckout/' + pseudoproduct.id)
    },
    formatStripePrice (isSuspendedActivation, stripePrice) {
      if (stripePrice) {
        if (!isSuspendedActivation) {
          return this.$utils.formatCurrency(stripePrice.unit_amount / 100)
        } else {
          return this.$utils.formatCurrency(0)
        }
      } else {
        return this.$utils.formatCurrency(0)
      }
    }
  }
}
</script>
