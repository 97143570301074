<template>
  <v-container>
    <app-api-call
      :call-result="callResult"
      class="mb-5"
    />
    <v-row
      justify="center"
    >
      <v-col
        v-for="(pseudoproduct, idx) in pseudoproducts"
        :key="idx"
        xl="2"
        lg="3"
        md="4"
        sm="7"
        xs="12"
        cols="12"
        class="my-1 d-flex justify-center"
      >
        <v-card
          class="pa-3 text-center elevation-2"
          :class="{ 'emphasize_active_product' : (pseudoproduct.metadata.isActiveProduct && !pseudoproduct.metadata.isSuspendedActivation)}"
          outlined
          tile
          max-width="240px"
          min-width="220px"
        >
          <PackageProductsCard
            class="mx-3 my-2 mr-2"
            :pseudoproduct="pseudoproduct"
            @activate-default="init()"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PackageProductsCard from './PackageProductsCard.vue'
export default {
  name: 'PackageProductsCardWrapper',
  components: {
    PackageProductsCard
  },
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      pseudoproducts: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.callResult.finished = false
      this.callResult.error = null
      this.$xapi.get('endpointsStripeProduct/packageProducts')
        .then(r => {
          this.pseudoproducts = r.data
          try {
            this.pseudoproducts = this.pseudoproducts.sort((a, b) => a.sortingAmount === b.sortingAmount ? 0 : a.sortingAmount > b.sortingAmount ? 1 : -1)
          } catch (e) {}
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        }).finally(() => {
          this.callResult.finished = true
        })
    }
  }
}
</script>

<style scoped lang="sass">
  .emphasize_active_product
    border: 2px solid $primary

</style>
