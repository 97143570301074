<template>
  <div class="font-weight-light">
    <div
      class="font-weight-medium text-h5"
      :class="{ 'primary--text' : (pseudoproduct.metadata.isActiveProduct && !pseudoproduct.metadata.isSuspendedActivation)}"
    >
      {{ $i18n.t(`$l.product.${pseudoproduct.name}`) }}
    </div>

    <div
      v-if="pseudoproduct.stripeYearPrice"
      style="line-height: 1.2;"
    >
      <div v-if="!pseudoproduct.isPremiumPackage">
        <span
          style="font-size:20px; position: relative; top:-40px;"
        >€</span>
        <span
          style="font-size:80px;"
          class="info--text"
        >{{ (pseudoproduct.stripeYearPrice.unit_amount/100/12).toFixed(0) }}</span>
        <span class="">/{{ $i18n.t('$l.product.month') }}</span>
      </div>
    </div>

    <div
      style="min-height: 6.5em;"
    >
      <div
        v-if="pseudoproduct.stripeMonthPrice"
      >
        <span class="ma-0 pa-0 mb-0">
          {{ $i18n.t('$l.product.forMonthSubscription') }}
        </span>
        <span
          style="font-size:22px;"
          class="info--text"
        > € {{ (pseudoproduct.stripeMonthPrice.unit_amount/100).toFixed(0) }}</span>
        <p class="ma-0 pa-0 mb-0">
          {{ $i18n.t('$l.product.forYearSubscription') }}
        </p>
      </div>

      <div
        v-if="pseudoproduct.isPremiumPackage"
        class="pt-6"
      >
        <span
          class="info--text"
        ><a
          href="https://www.okaml.eu/kontakt/"
          style="text-decoration: none;"
        >{{ 'Kontaktujte nás pre individuálne ceny a podmienky. Platba na faktúru.' }}</a></span>
      </div>
    </div>

    <PackageProductsButtons
      :pseudoproduct="pseudoproduct"
      :call-result="callResult"
      :style="pseudoproduct.isPremiumPackage?'padding-top: 80px;':''"
      @checkout-product="checkOut"
    />

    <app-api-call
      :call-result="callResult"
      class="mt-3"
    />

    <div
      v-for="item in getFeatures()"
      :key="item"
    >
      <p
        class="ma-0 pa-0 mt-2 font-weight-light"
        style="font-size:14px;"
      >
        {{ item }}
      </p>
    </div>

    <basic-dialog
      v-model="activateDefaultDialog"
      title="$l.app.warning"
      save-button
      save-button-label="$l.app.confirm"
      @close-dialog="activateDefaultDialog = false"
      @save-dialog="activateDefaultPackage()"
    >
      <v-container>
        {{ $i18n.t('$l.info.activateDefaultPackageInfo') }}
      </v-container>
    </basic-dialog>
  </div>
</template>

<script>
import PackageProductsButtons from './PackageProductsButtons.vue'

export default {
  name: 'PackageProductsCard',
  components: {
    PackageProductsButtons
  },
  props: {
    pseudoproduct: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      callResult: { finished: true, authorized: false, error: null, info: null },
      features: [],
      activateDefaultDialog: false
    }
  },
  methods: {
    getFeatures () {
      if (this.pseudoproduct.metadata) {
        let features = this.pseudoproduct.metadata[`features.${this.$i18n.locale}`]
        if (features) {
          return features.split(';')
        } else {
          return []
        }
      } else {
        return []
      }
    },
    checkOut (pseudoproduct) {
      if (pseudoproduct.isDefaultPackage) {
        this.activateDefaultDialog = true
      } else {
        this.$router.push('/productCheckout/' + pseudoproduct.id)
      }
    },
    activateDefaultPackage () {
      this.activateDefaultDialog = false
      this.callResult.finished = false
      this.callResult.error = null
      this.$xapi.get('endpointsUserProfile/activateDefaultPackage')
        .then(r => {
          this.$emit('activate-default')
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
        })
    }
  }
}
</script>
