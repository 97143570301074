<template>
  <div>
    <v-btn
      v-if="pseudoproduct.isDefaultPackage && !pseudoproduct.metadata.isActiveProduct"
      class="ma-0 pa-0 my-5"
      color="success"
      block
      outlined
      rounded
      :disabled="!pseudoproduct.metadata.nonDefaultPackageActivatedButSuspended || !!pseudoproduct.metadata.customerSubscriptionExists"
      :loading="!callResult.finished"
      @click="$emit('checkout-product', pseudoproduct)"
    >
      {{ $i18n.t('$l.product.activate') }}
    </v-btn>

    <v-btn
      v-if="buyPackageEnabled && !pseudoproduct.isPremiumPackage"
      class="ma-0 pa-0 my-5"
      color="info1"
      block
      outlined
      rounded
      @click="$emit('checkout-product', pseudoproduct)"
    >
      {{ $i18n.t('$l.product.buyPackage') }}
    </v-btn>

    <span
      v-if="pseudoproduct.metadata.customerSubscriptionExists && !pseudoproduct.isDefaultPackage"
    >

      <v-btn
        class="ma-0 pa-0 my-5"
        :style="pseudoproduct.isPremiumPackage?'visibility: hidden;':''"
        color="info1"
        block
        outlined
        rounded
        @click="$router.push('/settings')"
      >
        {{ $i18n.t('$l.paths.settings') }}
      </v-btn>
    </span>

    <div
      v-if="pseudoproduct.metadata.isActiveProduct && pseudoproduct.metadata.isSuspendedActivation"
      class="ma-0 mt-5 warning--text font-weight-medium"
    >
      {{ $i18n.t('$l.product.isSuspendedActivation') }}
    </div>

    <div
      v-if="pseudoproduct.metadata.isActiveProduct && pseudoproduct.metadata.isSuspendedInvestigations"
      class="ma-0 mt-5 warning--text font-weight-medium"
    >
      {{ $i18n.t('$l.product.isSuspendedInvestigations') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PackageProductsButtons',
  props: {
    pseudoproduct: {
      type: Object,
      required: true
    },
    callResult: {
      type: Object,
      required: true
    }
  },
  computed: {
    buyPackageEnabled () {
      if (!this.pseudoproduct.metadata.customerSubscriptionExists) {
        if (!this.pseudoproduct.isDefaultPackage) {
          return !this.pseudoproduct.metadata.isActiveProduct || this.pseudoproduct.metadata.isSuspendedActivation
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }
}
</script>
