<template>
  <v-container>
    <app-api-call
      :call-result="callResult"
    />
    <v-row
      justify="center"
    >
      <v-col
        v-for="(pseudoproduct, idx) in pseudoproducts"
        :key="idx"
        xl="2"
        lg="3"
        md="4"
        sm="7"
        xs="12"
        cols="12"
        class="my-1 d-flex justify-center"
      >
        <v-card
          class="pa-3 text-center elevation-2"
          outlined
          tile
          max-width="240px"
          min-width="220px"
        >
          <InvestigationPlusCard
            class="mx-3 my-2 mr-2"
            :pseudoproduct="pseudoproduct"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InvestigationPlusCard from './InvestigationPlusCard'
export default {
  name: 'InvestigationPlusCardWrapper',
  components: {
    InvestigationPlusCard
  },
  data () {
    return {
      callResult: { finished: true, authorized: false, error: null, info: null },
      pseudoproducts: []
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.pseudoproducts = null
      this.callResult.finished = false
      this.callResult.error = null
      this.$xapi.get('endpointsStripeProduct/investigationPlusProducts')
        .then(r => {
          this.pseudoproducts = r.data
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        }).finally(() => {
          this.callResult.finished = true
        })
    }
  }
}
</script>
